import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


    //     <head>
    //  <Helmet>
    //     <title>لحظه کارت</title>
    //     <meta name="لحظه کارت کارت هدیه ان اف تی" content="PAGE FOR DETAIL AND SHOP GIFT CARDS lahzecard" />
    //     <meta name="لحظه کارت" content="لحظه کارت, لحظهکارت, گیفت,کارت هدیه,gift card,lahze,card,lahze card " />

    // </Helmet>
    //     </head>

        const root = ReactDOM.createRoot(document.getElementById('root'));
        root.render(
            <App />
        );
